/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 27/3/2021
 * @Example
 */

import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import Config from '../../../../../config';
import Paging from '../../../../grid-container/paging';
import W39F3000GoalPlan from './W39F3000GoalPlan';

const W39F3000LeftCol = forwardRef((props, ref) => {
    const { classes, colorStatusArr, redirect, changeLoading, loadData, filter,
        selectedGoalPlan, changeSelectedGoalPlan, canvasHtml } = props;

    const goalListRef = useRef(null);

    const data = useSelector((state) => state.W39F3000.data) ?? { rows: [], total: 0 };

    const funcError = (btn, element) => {
        if (btn) {
            Object.assign(btn.style, {
                display: 'inline-flex',
            });
        }
        if (element) {
            Object.assign(element.style, {
                width: '100%',
            });
        }
    };

    const htmlToCanvas = async () => {
        if (goalListRef.current) {
            const element = goalListRef.current.querySelector(`[id='${selectedGoalPlan}']`);
            if (element) {
                const btn = element.querySelector('.W39F3000-Finish-Button');
                if (btn) {
                    Object.assign(btn.style, {
                        display: 'none',
                    });
                }
                
                Object.assign(element.style, {
                    width: 'fit-content',
                });

                const svgElements = element.querySelectorAll('svg');
                svgElements.forEach(function (item) {
                    item.setAttribute('width', item.getBoundingClientRect().width);
                    item.setAttribute('height', item.getBoundingClientRect().height);
                    item.style.width = null;
                    item.style.height = null;
                });

                await html2canvas(element, {
                    scale: 2,
                    allowTaint: true,
                    useCORS: true,
                    scrollX: 0,
                    scrollY: -window.scrollY,
                }).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    if (canvas && imgData) {
                        try {
                            funcError(btn, element);
                            canvasHtml.current.goalListImg = imgData;
                        } catch (err) {
                            funcError(btn, element);
                            Config.popup.show('ERROR', err);
                        }
                    } else {
                        funcError(btn, element);
                        Config.popup.show('ERROR', Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly'));
                    }
                });
            } else {
                Config.popup.show('ERROR', Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly'));
            }
        }
    };

    useImperativeHandle(ref, () => ({
        htmlToCanvas: htmlToCanvas,
    }));

    const onChangePage = (page) => {
        filter.current.skip = page * (filter.current?.limit ?? 20);
        loadData();
    };

    const onChangePerPage = (perPage) => {
        filter.current.skip = 0;
        filter.current.limit = perPage;
        loadData();
    };

    return (
        <div className={classes.goalPlanCont}>
            <div className={classes.goalListCont} ref={goalListRef}>
                {data?.rows?.map((row, idx) => (
                    <W39F3000GoalPlan
                        key={row?.GoalPlanID ?? idx}
                        classes={classes}
                        colorStatusArr={colorStatusArr}
                        redirect={redirect}
                        changeLoading={changeLoading}
                        loadData={loadData}
                        data={row}
                        selectedGoalPlan={selectedGoalPlan}
                        changeSelectedGoalPlan={changeSelectedGoalPlan}
                    />
                ))}
            </div>
            <Paging
                totalItems={data?.total ?? 0}
                skipPerPage={filter.current?.skip ?? 0}
                currentPage={Math.ceil((filter.current?.skip ?? 0) / (filter.current?.limit ?? 20))}
                itemPerPage={filter.current?.limit ?? 20}
                listPerPage={[10, 20, 40, 50]}
                className={classes.paging}
                onChangePage={(page) => {
                    if (onChangePage) onChangePage(page);
                }}
                onChangePerPage={(per) => {
                    if (onChangePerPage) onChangePerPage(per);
                }}
            />
        </div>
    );
});

W39F3000LeftCol.propTypes = {
    classes: PropTypes.object,
    colorStatusArr: PropTypes.array,
    redirect: PropTypes.func,
    changeLoading: PropTypes.func,
    loadData: PropTypes.func,
    filter: PropTypes.object,
    selectedGoalPlan: PropTypes.string,
    changeSelectedGoalPlan: PropTypes.func,
    canvasHtml: PropTypes.object,
};

W39F3000LeftCol.defaultProps = {
    classes: {},
    colorStatusArr: [],
    redirect: null,
    changeLoading: null,
    loadData: null,
    filter: { current: null },
    selectedGoalPlan: '',
    changeSelectedGoalPlan: null,
    canvasHtml: { current: {} },
};

export default W39F3000LeftCol;
