import React, { Component } from "react";
import { withStyles, IconButton, OutlinedInput, Typography } from "@material-ui/core";
import { FormGroup, Row, Col } from "react-bootstrap";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import Attachments from "../../../common/attachments/attachments";
import * as W39F1012Actions from "../../../../redux/W3X/W39F1012/W39F1012_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import Modal from "../../../common/modal/modal";
import ButtonGeneral from "../../../common/button/button-general";
import _ from "lodash";
import CDN from "../../../CDN";
import Api from "../../../../services/api";
import Fade from "@material-ui/core/Fade";

const styles = (theme) => ({
    caption: {
        paddingTop: "7%",
        [theme.breakpoints.down("md")]: {
            paddingTop: 0,
        },
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        "& :first-child": {
            fill: theme.palette.primary.main,
        },
    },
});

class W39F1012 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataForm: [],
            dataOldAttachments: [],
            uploading: false,
            attachmentLoading: false,
            loadingForm: false,
            saving: false,
        };
        this.attachments = [];
        this.deletedFile = [];
    }

    handleResponse = (res = {}) => {
        const status = _.get(res, "data.Status", 1);
        const message = _.get(res, "data.Message", "");
        const messageErr = _.get(res, "message", false);
        if (messageErr) Config.popup.show("INFO", messageErr || Config.lang("DHR_Loi_chua_xac_dinh"));
        if (!status) {
            this._removeCDN();
            Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
            this.onClose();
        } else if (message !== "") {
            Config.popup.show("INFO", message);
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: _.get(data, "TransID", ""),
        };
        this.setState({ attachmentLoading: true });
        this.props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            this.setState({ attachmentLoading: false }, () => {
                this.handleResponse(err);
            });
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
        });
    };

    loadFormInfo = () => {
        const { data } = this.props;
        const params = {
            TransID: _.get(data, "TransID", ""),
        };
        this.setState({ loadingForm: true });
        this.props.w39f1012Actions.getForm(params, (err, data) => {
            this.setState({ loadingForm: false }, () => {
                this.handleResponse(err);
            });
            const arrCaption = _.get(data, "captionGrid", {});
            const arrInfo = _.get(data, "dataInfoRef", {});
            const dataForm = arrCaption.map((item) => {
                const keyInfo = Object.keys(arrInfo).filter((info) => _.startsWith(info, item.RefID));
                return { ...item, [keyInfo]: arrInfo[keyInfo] };
            });
            this.setState({ dataForm });
        });
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    };

    onStore = async () => {
        const { data } = this.props;
        const { dataForm } = this.state;
        const arrAttachments = this._getAttachments();
        const TransID = _.get(data, "TransID", "");
        const objRefEval = dataForm
            .map((item) => _.pickBy(item, (v, k) => _.startsWith(k, "RefEvaluation")))
            .reduce((obj, item) => {
                const keyName = Object.keys(item);
                obj[keyName] = item[keyName];
                return obj;
            }, {});
        const params = {
            ...objRefEval,
            TransID,
            arrAttachment: JSON.stringify(arrAttachments),
        };
        this.setState({ saving: true, attachmentLoading: true });
        const res = await Api.put("/w39f1012/save", params);
        this.setState({ saving: false, attachmentLoading: false });
        this.handleResponse(res);
    };

    handleChange = (key, e, i) => {
        const { dataForm } = this.state;
        const value = _.get(e, "target.value", "");
        this.setState({
            dataForm: [
                ...dataForm.slice(0, i),
                {
                    ...dataForm[i],
                    [key]: value,
                },
                ...dataForm.slice(i + 1),
            ],
        });
    };

    renderDataForm = () => {
        const { classes } = this.props;
        const { dataForm, saving, loadingForm } = this.state;
        return dataForm.map((item, i) => {
            const refEvaluationName = `RefEvaluation${("0" + (i + 1)).slice(-2)}U`;
            const styleHidden = { display: item.Disabled ? "none" : "block" };
            return (
                <FormGroup key={i} style={{ ...styleHidden }}>
                    <Fade in={!loadingForm} timeout={i * 500}>
                        <Row>
                            <Col md={3} xs={12}>
                                <div className={classes.caption}>
                                    <Typography align="left" component="h6">
                                        {item.RefCaptionU}
                                    </Typography>
                                </div>
                            </Col>
                            <Col md={9} xs={12}>
                                <OutlinedInput
                                    disabled={saving}
                                    fullWidth={true}
                                    value={item[refEvaluationName]}
                                    onChange={(e) => this.handleChange(refEvaluationName, e, i)}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    multiline={true}
                                    labelWidth={0}
                                />
                            </Col>
                        </Row>
                    </Fade>
                </FormGroup>
            );
        });
    };

    render() {
        const { dataOldAttachments, uploading, attachmentLoading, saving } = this.state;
        return (
            <>
                <Modal.Content>
                    {this.renderDataForm()}
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Attachments
                            isAttachInfo
                            ref={(ref) => (this.attRef = ref)}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={uploading}
                            maxLength={5}
                            uploading={attachmentLoading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton aria-label="view" onClick={this.onAttachment}>
                            <AttachFileIcon />
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            disabled={saving || uploading}
                            onClick={this.onStore}
                        />
                    </div>
                </Modal.Actions>
            </>
        );
    }

    componentDidMount = () => {
        this.loadFormInfo();
        this.loadAttachments();
    };
}

export default compose(
    connect(
        (state) => ({
            getForm: state.W39F1012.getForm,
        }),
        (dispatch) => ({
            w39f1012Actions: bindActionCreators(W39F1012Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles),
)(W39F1012);
