/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 14/5/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useGoalStyle = makeStyles((theme) => ({
    svg: {
        opacity: 0,
        animation: '$showSVG 0.1s forwards',
        animationDelay: (props) => `calc(0.1s * ${props.numberOfTR})`,
    },
    '@keyframes showSVG': {
        to: {
            opacity: 1,
        },
    },
}));
