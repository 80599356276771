/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 30/3/2021
 * @Example
 */

import { Avatar, CircularProgress, Collapse, Typography, Tooltip } from "diginet-core-ui/components";
import Icon, { ArrowUp } from 'diginet-core-ui/icons';
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InlineSVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../../config";
import * as W39F3000Actions from "../../../../../redux/W3X/W39F3000/W39F3000_actions";
import { useGoalStyle } from '../goalStyle';

W39F3000Goal.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    colorStatusArr: PropTypes.array,
    redirect: PropTypes.func,
    changeLoading: PropTypes.func,
    numberOfTR: PropTypes.string,
};

function W39F3000Goal(props) {
    const { classes, data, colorStatusArr, redirect, changeLoading, numberOfTR } = props;

    const [expandTargetResult, setExpandTargetResult] = useState(false);
    const [dataTargetResult, setDataTargetResult] = useState([]);
    const [position, setPosition] = useState(null);

    const dispatch = useDispatch();
    const detailData = useSelector((state) => state.W39F3000.detailData);

    useEffect(() => {
        if (data?.Type === 'Object' && dataTargetResult.length > 0) {
            const collapseTR = document.getElementById(`collapseTR-${data?.GoalID ?? ''}`);
            const expand = document.getElementById(`iconExpand-${data?.GoalID ?? ''}`);
            const goal = expand?.parentNode?.parentNode;
            const goalHeight = goal?.offsetHeight;
            const lastChild = collapseTR?.lastChild;
            if (lastChild && goalHeight) {
                const height = lastChild?.offsetHeight ?? 0;
                const top = lastChild?.offsetTop ?? 0;
                const bottom = height / 2;
                const y = height / 2 + top + goalHeight / 2 - 20;
                setPosition({ y, bottom });
            }
        }
    }, [data, dataTargetResult]);

    const toogleExpandTargetResult = () => {
        setExpandTargetResult(!expandTargetResult);
        if (!expandTargetResult && dataTargetResult.length === 0) {
            const param = {
                DivisionID: Config.getDivisionID() || "",
                FormID: "W39F3000",
                Language: Config.language || "84",
                UserID: Config.profile.UserID || "",
                GoalID: data.GoalID || "",
            };

            changeLoading(true);
            dispatch(W39F3000Actions.getTargetResult(param, (err, data) => {
                changeLoading(false);
                if (err) return Config.popup2.show("ERROR", err);
                setDataTargetResult(data);
            }));
        }
    };

    const getColorCode = (status, validDateTo, finishDate) => {
        let color = colorStatusArr[0].colorCode;
        const today = moment();
        if (validDateTo) {
            if (status === 0) {
                if (today.isAfter(validDateTo)) {
                    color = colorStatusArr[1].colorCode;
                }
                else {
                    color = colorStatusArr[0].colorCode;
                }
            }
            if (finishDate && status === 1) {
                if (finishDate.isAfter(validDateTo)) {
                    color = colorStatusArr[3].colorCode;
                }
                else {
                    color = colorStatusArr[2].colorCode;
                }
            }
        }
        return color;
    };

    const validDateTo = data.ValidDateTo ? moment(data.ValidDateTo) : null;
    const finishDate = data.FinishDate ? moment(data.FinishDate) : null;
    const color = getColorCode(data.Status, validDateTo, finishDate);
    const user = Config.getUser({ EmployeeID: data.EmployeeID });
    const numOfTR = data?.NumberOfKey?.split('/')?.[1] ?? 1;
    const goalStyle = useGoalStyle({ numberOfTR: numberOfTR ?? numOfTR });

    return (
        <div className={`${data?.Type === 'Key' ? classes.centerRow : ''}`}>
            {data?.Type === 'Key' &&
                <svg height={6} width={45} stroke={'#7F828E'} strokeWidth={1} fill={'#7F828E'} className={goalStyle.svg}
                    style={{ overflow: 'visible' }}>
                    <line x1={0} y1={3} x2={42} y2={3} />
                    <circle cx={42} cy={3} r={2} />
                </svg>}
            <div className={`${data?.Type === "Key" ? classes.gridTR : classes.gridGoal} ${classes.divGoal} ${classes.flexGap10}`}>
                <div className={`${classes.centerRow} ${classes.flexGap15} ${classes.relativePos}`}>
                    {data.Type === "Object" && <span id={data?.Type === 'Object' ? `iconExpand-${data?.GoalID ?? ''}` : ''} onClick={toogleExpandTargetResult} className={`${classes.iconExpandTransition} ${classes.pointerCursor}`}
                        style={{ color: '#7F828E', transform: expandTargetResult ? '' : 'rotate(90deg)' }}>
                        <ArrowUp width={24} height={24} viewBox color={"currentColor"} />
                    </span>}
                    <div className={`${classes.flexColumn} ${classes.flexGap5} ${classes.hiddenOverflow}`}>
                        <Tooltip arrow title={data?.GoalName ?? ''} viewportPadding={20} style={{ width: '100%' }}>
                            <Typography type={'h3'} onClick={e => redirect('detail', data)} className={`${classes.pointerCursor} ${classes.textOverflow}`}>{data?.GoalName ?? ''}</Typography>
                        </Tooltip>
                        <div className={`${classes.centerRow} ${classes.flexGap20}`}>
                            {data.Type === "Object" && <div className={`${classes.centerRow} ${classes.flexGap5} ${classes.pointerCursor}`} onClick={e => redirect('detail', data)}>
                                <InlineSVG src={require("../icons/number_of_key.svg")} width={24} height={24} />
                                <Typography type={'p2'} color={"secondary"} style={{ fontSize: 16 }}>{data.NumberOfKey || ""}</Typography>
                            </div>}
                            <div className={`${classes.centerRow} ${classes.flexGap5} ${data.NumComment > 0 ? classes.pointerCursor : ''}`}
                                onClick={e => { if (data.NumComment > 0) redirect('comment', data) }}>
                                <InlineSVG src={require("../icons/comment.svg")} width={24} height={24} />
                                <Typography type={'p2'} color={"secondary"} style={{ fontSize: 16 }}>{data.NumComment || 0}</Typography>
                            </div>
                        </div>
                    </div>
                    {detailData?.IsEdit === 1 && <Icon disabled={detailData?.GoalAppStatus  === 1 || detailData?.GoalAppStatus === 2} id="iconEdit" name="Edit" type="filled" onClick={e => redirect('edit', data)}
                        style={{ visibility: 'hidden', color: "#7F828E", background: "white", boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)', position: 'absolute', right: '7%' }} />}
                </div>
                <div className={`${classes.flexColumn} ${classes.flexGap5}`} style={{ width: 120, alignItems: 'center' }}>
                    <CircularProgress
                        color={color}
                        determinate
                        direction="bottom"
                        percent={data.Result || 0}
                        percentColor="#7F828E"
                        size="sm"
                        strokeWidth={8}
                    />
                    {data.ComputeMethod === "Target" && <Typography type={'p2'} color={"secondary"}>{data.Value || 0}/{data.Target || 0}</Typography>}
                </div>
                <div style={{ width: 80 }}>
                    <Typography type={'p2'}>{data.Proportion || 0}%</Typography>
                </div>
                <div style={{ width: 120 }}>
                    <Typography type={'p2'}>{validDateTo?.format('DD/MM/YYYY') || ''}</Typography>
                </div>
                <div className={`${classes.flexColumn} ${classes.flexGap5}`} style={{ width: 140 }}>
                    <Avatar
                        clearAble={false}
                        width={24}
                        height={24}
                        readOnly
                        src={user?.UserPictureURL || ''}
                        outlined={'1px solid white'}
                        hoverAble
                        data={{
                            [Config.lang('Chuc_vu')]: user?.DutyName || '',
                            [Config.lang('Phong_ban')]: user?.DepartmentName || '',
                        }}
                    />
                    <Typography type={'p2'}>{user?.EmployeeName || ''}</Typography>
                </div>
            </div>
            {data.Type === "Object" && dataTargetResult.length > 0 &&
                <div style={{ marginLeft: 12, width: 'calc(100% - 12px)', position: 'relative' }}>
                    {data.Type === "Object" && dataTargetResult.length > 0 && position && expandTargetResult &&
                        <svg
                            height={position?.y ?? 0}
                            width={2}
                            stroke={'#7F828E'}
                            strokeWidth={2}
                            className={goalStyle.svg}
                            style={{
                                position: 'absolute',
                                bottom: position?.bottom ?? '',
                            }}
                        >
                            <line x1={0} y1={0} x2={0} y2={position.y ?? 0} />
                        </svg>}
                    <Collapse
                        open={expandTargetResult}
                        id={`collapseTR-${data?.GoalID ?? ''}`}
                        style={{ overflowX: 'visible', overflowY: 'clip' }}
                    >
                        {data.Type === "Object" && dataTargetResult.length > 0 && expandTargetResult &&
                            dataTargetResult.map(i =>
                                <W39F3000Goal key={i.GoalID} classes={classes} data={i} colorStatusArr={colorStatusArr} redirect={redirect} changeLoading={changeLoading} numberOfTR={numOfTR} />)}
                    </Collapse>
                </div>
            }
        </div>

    );
}

export default W39F3000Goal;