/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/14/2020
 * @Example 
 */
import React from "react";
import { connect } from "react-redux";
import * as W39F2001Actions from "../../../../redux/W3X/W39F2001/W39F2001_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { browserHistory } from "react-router";
import { Column } from 'devextreme-react/data-grid';
import { Row, Col, FormGroup } from "react-bootstrap"
import GridContainer from "../../../grid-container/grid-container";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import IconButton from "@material-ui/core/IconButton";
import * as generalActions from "../../../../redux/general/general_actions";
import {FormLabel as Label, Icon} from "@material-ui/core";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Rating from "@material-ui/lab/Rating";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";
import {Combo} from "../../../common/form-material";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005"
import HistoryIcon from "@material-ui/icons/History"
import _ from "lodash";
const styles = theme => ({
    divText: {
        overflow: "hidden",
        whiteSpace: "pre-line"

    },
    iconEmpty: {
        color: theme.palette.warning.main
    },
    iconCheckRoot: {
        color: theme.palette.info.main
    },
    iconCheckRootError: {
        color: `${theme.palette.error.main}!important`
    }
});
class W39F2001 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataGrid: {
                rows: [],
                total: 0
            },

            dataCboEmployees: {
                rows: [],
                total: 0
            },

            cboDepartmentLoading: false,
            cboStatusLoading: false,
            cboEmployeesLoading: false,
            loading: false,
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
        };
        this.filter = {
            DateFrom: null,
            DateTo: null,
            AppStatusID: "",
            DepartmentID: "",
            Employee: null,
            WorkFormID: "",
            limit: 10,
            skip: 0
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W39F2001", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2070",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({cboEmployeesLoading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({cboEmployeesLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboAppStatus = () => {
        const params = {
            Language: Config.language || '84',
            FormID: "W39F2001"
        };
        this.setState({ cboStatusLoading: true });
        this.props.generalActions.getCboAppStatus(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ cboStatusLoading: false });
        });
    };

    loadCboDepartments = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: "W39F2001",
            Language: Config.language || '84'
        };

        this.setState({ cboDepartmentLoading: true });
        this.props.generalActions.getCboDepartments(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ cboDepartmentLoading: false });
        });
    };

    loadCboContractTypes = () => {
        this.setState({ cboCTypesLoading: true });
        this.props.w39F2001Actions.getContractTypes(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ cboCTypesLoading: false });
        });
    };

    loadDataGrids = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { skip, limit, DateFrom, DateTo, WorkFormID, DepartmentID, AppStatusID, Employee } = this.filter;
        let params = {
            TransID: VoucherID || "",
            Language: Config.language || "84",
            Mode: 0,
            FormID: "W39F2001",
            DateFrom: DateFrom || null,
            DateTo: DateTo || null,
            AppStatusID: AppStatusID || "",
            DepartmentID: DepartmentID || "",
            EmployeeID: Employee && Employee.EmployeeID ? Employee.EmployeeID : "",
            WorkFormID: WorkFormID || "",
            skip,
            limit
        };
        this.setState({ loading: true });
        this.props.w39F2001Actions.getDataGrids(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ loading: false });
                return false;
            }
            this.setState({
                dataGrid: {
                    rows: data && data.rows ? data.rows : [],
                    total: data && data.total ? data.total : 0,
                    // rows: [{}],
                    // total: 1,
                }
            }, () => {
                const getKeyIDReminder = _.get(this.props,"location.state.data.keyID", false)
                this.setState({ loading: false });
                 if(getKeyIDReminder) {
                     this.onView({
                     row: {data: _.get(_.filter(_.get(data, "rows", []),
                                 item => item.TransID === getKeyIDReminder), "[0]", {})
                     }
                 })
                 }
            });
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        // this.loadCboLeaveTypes();
        this.loadDataGrids();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataGrid} = this.state;
        Config.callChildForm({
            FormID: "W39F2001",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
            onLoad: (params) => this.loadDataGrids(params && params.ID),
            onAction: (e, data) => this.onView({row: { data: data }})
        }, this.props);
    }

    onHistoryApproval = (e, data = {}) => {
        this.setState({ w84F3005PopupData: { TransID: data.TransID }, showW84F3005Popup: true })
    }
    onLoadDataFilter = () => {
        this.loadCboEmployees();
        this.loadCboAppStatus();
        this.loadCboDepartments();
        this.loadCboContractTypes();
    };

    onFilter = () => {
        this.loadDataGrids();
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrids();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrids();
    };

    onView = (e, isReminder = false) => {
        const {location} = this.props;
        const { data } = e.row;
        const status = data && data.EvaluatedStatus ? data.EvaluatedStatus : 0;
        const dataReminder = isReminder ? {..._.get(location, "state.data", {})} : {}
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1011",
            state: {
                Mode: status ? 1 : 0,
                FormID: "W39F2001",
                ApproveLevel: data && data.ApproveLevel ? data.ApproveLevel : 0,
                TransID: data && data.TransID ? data.TransID : "",
                TransTypeID: data && data.TransTypeID ? data.TransTypeID : "",
                EvaluationVoucherID: data && data.EvaluationVoucherID ? data.EvaluationVoucherID : "",
                IsSave: data && (data.IsSave || data.IsSave === 0) ? data.IsSave : 1,
                UserLevel: data && data.UserLevel ? data.UserLevel : 1,
                EmployeeID: data && data.EmployeeID ? data.EmployeeID : "",
                dataReminder
            }
        });
    };

    onDbRowClick = (e) => {
        if (!e) return false;
        const {data} = e;

        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1011",
            state: {
                Mode: 1,
                FormID: "W39F2001",
                ApproveLevel: data && data.ApproveLevel ? data.ApproveLevel : 0,
                TransID: data && data.TransID ? data.TransID : "",
                TransTypeID: data && data.TransTypeID ? data.TransTypeID : "",
                EvaluationVoucherID: data && data.EvaluationVoucherID ? data.EvaluationVoucherID : "",
                IsSave: data && (data.IsSave || data.IsSave === 0) ? data.IsSave : 1,
                UserLevel: data && data.UserLevel ? data.UserLevel : 1,
                EmployeeID: data && data.EmployeeID ? data.EmployeeID : "",
            }
        });
    };

    handleFilterChange = (key, e) => {
        if (!key || !e) return false;
        switch (key) {
            case "DateFrom":
                this.filter.DateFrom = e.value;
                break;
            case "DateTo":
                this.filter.DateTo = e.value;
                break;
            case "AppStatusID":
                this.filter.AppStatusID = e.value;
                break;
            case "DepartmentID":
                this.filter.DepartmentID = e.value;
                break;
            case "EmployeeID":
                this.filter.Employee = e.value;
                break;
            case "WorkFormID":
                this.filter.WorkFormID = e.value;
                break;
            default:
                break;
        }
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: data.EmployeeID}
        });
    };

    renderFilters = () => {
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    const {dataCboEmployees, cboEmployeesLoading, cboStatusLoading, cboDepartmentLoading} = this.state;
                    const {getCboAppStatus, getCboDepartments, getContractTypes} = this.props;
                    const {AppStatusID, DepartmentID, Employee, WorkFormID ,DateFrom, DateTo} = this.filter;
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                placeholder={"DD/MM/YYYY"}
                                                max={DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={DateFrom}
                                                onValueChanged={(e) => this.handleFilterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                min={DateFrom}
                                                placeholder={"DD/MM/YYYY"}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={DateTo}
                                                onValueChanged={(e) => this.handleFilterChange("DateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_danh_gia")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            loading={cboStatusLoading}
                                            onValueChanged={(e) => this.handleFilterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID || "" }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("DHR_Phong_ban")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            valueExpr={"DepartmentID"}
                                            displayExpr={"DepartmentName"}
                                            loading={cboDepartmentLoading}
                                            onValueChanged={e => this.handleFilterChange("DepartmentID", e)}
                                            showClearButton={true}
                                            value={DepartmentID || ""}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={Employee === "%" ? null : Employee}
                                            loading={cboEmployeesLoading}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nhan_vien')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.handleFilterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("DHR_Loai_hop_dong")}
                                            dataSource={Config.storeDataSoureDevExtreme(getContractTypes)}
                                            valueExpr={"WorkFormID"}
                                            displayExpr={"WorkFormName"}
                                            onValueChanged={e => this.handleFilterChange("WorkFormID", e)}
                                            showClearButton={true}
                                            value={WorkFormID || ""}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onFilter}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderPicture = (e) => {
        const { data } = e.row;
        return (
            <div>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
            </div>
        );
    };

    renderItem = (e) => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                {/*<UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>*/}
                <div className={"w75f2000_info"}>
                    <div style={{ fontSize: '1.12rem' }}><UserName data={data}/></div>
                    <div style={{ fontWeight: 200 }}>
                        {Config.convertDate(data.AssessDate, "","DD/MM/YYYY, kk:mm:ss", true)}
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = (e) => {
        const { data } = e.row;
        const { classes } = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>
                    {Config.lang("DHR_Loai_danh_gia")}:&nbsp;{data.TransTypeName || ""}
                </div>
                {data.WorkFormName && <div>
                    {Config.lang("DHR_Loai_hop_dong")}:&nbsp;{data.WorkFormName || ""}
                </div>}
                {data.Note && <div>
                    {Config.lang("DHR_Ghi_chu")}:&nbsp;{data.Note || ""}
                </div>}
                {data.AssessStatus && <div style={{color: "red"}}>
                    {data.AssessStatus || ""}
                </div>}
            </div>
        );
    };

    renderAction = (e) => {
        const { data } = e.row;
        const {classes} = this.props;
        let status = data && data.EvaluatedStatus ? data.EvaluatedStatus / 2 : 0;
        const approvalStatus = data && Number(data.ApproveStatus);

        return (
            <div className={"display_row align-center valign-middle"}>
                <IconButton aria-label="view" className={"mgr10"} size="small" onClick={() => this.onHistoryApproval(e,data)}>
                        <HistoryIcon/>
                </IconButton>
                <IconButton aria-label="view" className={"mgr10"} size="small" onClick={() => this.onView(e)}>
                   <Rating
                        max={1}
                        value={status}
                        readOnly
                        size={"large"}
                        precision={0.5}
                        classes={{iconEmpty: classes.iconEmpty}}
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    />
                </IconButton>
                {
                    data?
                        approvalStatus !== 0 && approvalStatus !== 1?
                            <Rating
                                max={1}
                                value={1}
                                readOnly
                                size={"large"}
                                className={`mgr10 `}
                                
                                classes={{root: classes.iconCheckRootError}}
                                
                                icon={<Icon className="fas fa-times"/>}
                            />
                        :data.IsApproved?
                            <Rating
                                max={1}
                                value={approvalStatus}
                                readOnly
                                size={"large"}
                                className={`mgr10 `}                           
                                classes={{root: classes.iconCheckRoot}}                            
                                icon={<Icon className="fas fa-check"/>}
                            />
                        :null
                        :null
                }
            </div>
        );
    };

    render() {
        let { iPermission, dataGrid, loading  , showW84F3005Popup, w84F3005PopupData = {},} = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
                <W84F3005 open={showW84F3005Popup} onClose={e => this.setState({ showW84F3005Popup: false })} FormID="W39F2001" TransID={w84F3005PopupData.TransID} />
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                    <ActionToolbar title={Config.lang("DHR_Danh_gia_nhan_vien_het_han_HDLD")} />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{ border: 'none' }}
                                dataSource={dataGrid && dataGrid.rows}
                                totalItems={dataGrid && dataGrid.total}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid()}
                                typePaging={"remote"}
                                columnAutoWidth={true}
                                loading={loading}
                                showRowLines={false}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                noDataText={Config.lang("DHR_No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onDbRowClick={this.onDbRowClick}
                            >
                                <Column cellRender={this.renderPicture} width={70} />
                                <Column
                                    dataField={'EmployeeName'}
                                    cellRender={this.renderItem}
                                    width={200}
                                // width={300}
                                />
                                <Column cellRender={this.renderInfo} minWidth={300}/>
                                <Column cellRender={this.renderAction} width={150} alignment={"center"} />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect((state) => ({
    getCboAppStatus: state.general.getCboAppStatus,
    getCboDepartments: state.general.getCboDepartments,
    getContractTypes: state.W39F2001.getContractTypes,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w39F2001Actions: bindActionCreators(W39F2001Actions, dispatch)
})), withStyles(styles, {withTheme: true}))(W39F2001);
