/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/7/2020
 * @Example 
 */

import React from "react";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { Image, FormGroup, Row, Col } from "react-bootstrap";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import { browserHistory } from "react-router";
import UserImage from "../../../common/user/user-image";

import * as W39F1020Actions from "../../../../redux/W3X/W39F1020/W39F1020_actions";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import Icons from "../../../common/icons/";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757",
        whiteSpace: 'pre-line',
        // overflow: 'hidden',
        // maxWidth: 300,
        // textOverflow: "ellipsis"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    }
};

class W39F1020 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            rowData: null,
            VoucherDateFrom: null,
            VoucherDateTo: null,
            dataGrid: [],
            totalDataGrid: 0,
        };
        this.loading = {
            cboEmployees: false,
            cboProjects: false,
        };
        this.filter = {
            DepartmentID: "",
            AppStatusID: "",
            skip: 0,
            limit: 10
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W39F1020", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };
    loadCboDepartment = () => {
        const param = {
            HostID: "",
            FormID: "W39F12020",
            DivisionID: Config.getDivisionID() ? Config.getDivisionID() : "QT" ,
            Language: Config.language || "84"
        };

        this.props.generalActions.getCboDepartments(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID }
        });
    };

    redirectScreen = (mode, e = "") => {
        const data = e && e.data;
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1025",
            state: {
                mode: mode,
                ...(e && e.data ? {
                    ...data
                } : {})
            }
        });
    };

    renderEmpProfile = e => {
        const { classes } = this.props;
        const { data } = e.row;
        const date = moment.utc(data.CreateDate).format("DD/MM/YYYY, kk:mm");
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>
                    <div className={classes.divText}>{data.TransTypeID || ""}</div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {data.CreateDate && date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        const { classes } = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>{data.ProNotice || ""}</div>
                <div>
                    {Config.lang("DHR_Loai_danh_gia")}:{data.TransTypeName || ""}
                </div>
                {data.StrDepartment && (
                    <div>
                        {Config.lang("DHR_Phong_ban")}: {data.StrDepartment || ""}
                    </div>
                )}
                {data.Note && (
                    <div>
                        {Config.lang("DHR_Ghi_chu")}: {data.Note || ""}
                    </div>
                )}
                {data.VoucherDate && (
                    <div>
                        {Config.lang("DHR_Ngay_danh_gia")}: { moment.utc(data.VoucherDate).format("DD/MM/YYYY") || ""}
                    </div>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const {getCboAppStatus} = this.props;
        const { data } = e.row;
        const item = getCboAppStatus.find(item => Number(item.AppStatusID) === Number(data.StatusID));
        const status = {
            AppStatusID: data.StatusID,
            AppStatusName: item && item.AppStatusName ? item.AppStatusName : "",
        };
        return (
            <Status data={status} />
        );
    };

    onEdit = (e) => {
        this.redirectScreen("edit", e);
    };

    onAdd = () => {
        this.redirectScreen("add");
    };

    onDelete = data => {
        const { EvaluationVoucherID } = data;
        const params = {
            FormID: "W39F1020",
            EvaluationVoucherID: EvaluationVoucherID,
            Mode: 0
        };

        Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => {
                this.props.W39F1020Actions.deleteDataGrid(params, error => {
                    if (error) {
                        Config.popup.show("INFO", Config.lang("DHR_Loi_chua_xac_dinh"));
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("DHR_Xoa_thanh_cong"), 3000);
                        this.loadDataGrid();
                    }
                });
            }
        );
    };

    renderAction = e => {
         const {iPermission} = this.state;
        const { data } = e.row;
        return (
            <div className={"display_row align-center"}>
                <IconButton
                    disabled={!iPermission > 0}
                    aria-label="view"
                    size="small"
                    onClick={() => this.onEdit(e)}
                >
                    <Icons name={"view"}/>
                </IconButton>
                <Divider
                    orientation="vertical"
                    style={{ width: 2, height: 11, margin: "0 5px" }}
                />
                <IconButton
                     disabled={data && (!(iPermission > 3) || (Number(data.StatusID) === 1))}
                    aria-label="delete"
                    size="small"
                    onClick={() => this.onDelete(data)}
                >
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        );
    };

    filterChange = (key, data) => {
        const { value } = data;
        if (!key) return false;
        switch (key) {
            case "VoucherDateFrom":
                this.setState({ VoucherDateFrom: value });
                break;
            case "VoucherDateTo":
                this.setState({ VoucherDateTo: value });
                break;
            case "EmployeeID":
                this.filter.Employee = value;
                break;
            case "AppStatusID":
                this.filter.AppStatusID =  value;
                break;
            case "DepartmentID":
                this.filter.DepartmentID = value;
                break;
            default:
                break;
        }
    };

    renderFilter = () => {
        const {
            getCboDepartments,
            getCboAppStatus,
            classes
        } = this.props;
        const { AppStatusID, DepartmentID } = this.filter;
        const { VoucherDateFrom, VoucherDateTo } = this.state;

        return (
            <Filter
                placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>
                                                {Config.lang("DHR_Tu")}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{ max: VoucherDateTo }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("VoucherDateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={VoucherDateFrom && VoucherDateFrom}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>
                                                {Config.lang("DHR_Den").toLowerCase()}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: VoucherDateFrom }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("VoucherDateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={VoucherDateTo && VoucherDateTo}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Phong_ban")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            valueExpr={"DepartmentID"}
                                            displayExpr={"DepartmentName"}
                                            onValueChanged={e => this.filterChange("DepartmentID", e)}
                                            showClearButton={true}
                                            value={DepartmentID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "38px",
                                    paddingBottom: "40px"
                                }}
                            >
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadDataGrid();
        this.loadCboAppStatus();
        this.loadCboDepartment();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataGrid} = this.state;
        Config.callChildForm({
            FormID: "W39F1020",
            // ID: voucher_id,
            keyExpr: "EvaluationVoucherID",
            data: dataGrid || [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.redirectScreen("edit", { data: data })
        }, this.props);
    }

    loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { VoucherDateFrom, VoucherDateTo } = this.state;
        const {DepartmentID, AppStatusID, limit, skip} = this.filter;
        const param = {
            TransID: VoucherID || "",
            DivisionID: Config.getDivisionID() ? Config.getDivisionID() : "QT" ,
            VoucherDateFrom: VoucherDateFrom || null,
            VoucherDateTo: VoucherDateTo || null,
            StatusID: AppStatusID || AppStatusID === 0 ? String(AppStatusID) : "",
            DepartmentID: DepartmentID ? DepartmentID : "",
            FormID: "W39F1020",
            skip: skip,
            limit: limit
        };
        this.setState({ gridLoading: true });
        this.props.W39F1020Actions.getGrid(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
            if(data) {
                this.setState({
                    dataGrid: data.rows ? data.rows : data,
                    totalDataGrid: data.total,
                });
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W39F1020",
            Language: Config.language || 84
        };

        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    render() {
        const { iPermission, gridLoading,  dataGrid, totalDataGrid } = this.state;
        if (iPermission <= 0) return null;

        const { skip, limit } = this.filter;
        return (
            <>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("DHR_Danh_gia_phong_ban")}>
                    <ButtonGeneral
                        name={Config.lang("DHR_Them")}
                        typeButton={"add"}
                        disabled={!iPermission > 1}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={dataGrid}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    rowAlternationEnabled={false}
                    noDataText={Config.lang("DHR_No_data")}
                    onCellClick={this.onDetail}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={totalDataGrid}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onDbCellClick={e => this.redirectScreen("edit", e)}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile} width={280}/>
                    <Column cellRender={this.renderInfo} minWidth={350}/>
                    <Column cellRender={this.renderStatus} width={200} alignment={"center"}/>
                    <Column cellRender={this.renderAction} width={100} alignment={"center"}/>
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboDepartments: state.general.getCboDepartments,
            getCboAppStatus: state.general.getCboAppStatus,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W39F1020Actions: bindActionCreators(W39F1020Actions, dispatch)
        })
    ),
    withStyles(styles)
)(W39F1020);
